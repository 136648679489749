import React, { useState } from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import { pageTitle } from '../../helpers/PageTitle';
import { Icon } from '@iconify/react';

export default function ContactPage() {
  pageTitle('Contact');
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleMailtoClick = (e) => {
    e.preventDefault();
    const { fullName, email, phone, message } = formData;
    const mailtoLink = `mailto:atlas.activesim@gmail.com?subject=Contact Form Submission&body=Name: ${fullName}%0AEmail: ${email}%0APhone: ${phone}%0AMessage: ${message}`;
    window.location.href = mailtoLink;
  };

  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Get in touch for more <br/>information and support"
        subTitle="Contact"
        shape="shape_6"
      />
      <Spacing lg="75" md="60" />
      <section>
        <div className="container">
          <div className="row align-items-center cs_gap_y_45">
            <div className="col-lg-6">
              <h2 className="cs_fs_50">Get in touch and explore options!</h2>
              <div className="cs_height_55 cs_height_lg_30" />
              <ul className="cs_mp0 cs_contact_info">
                <li>
                  <h3 className="cs_fs_29 cs_semibold">Email:</h3>
                  <p className="mb-0">atlas.activesim@gmail.com</p>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <div className="cs_contact_form_wrap">
                <div className="cs_gray_bg_3 cs_contact_form_bg" />
                <form className="cs_contact_form">
                  <label className="cs_fs_21 cs_semibold cs_primary_color">Your full name</label>
                  <input
                    placeholder="Type your name"
                    type="text"
                    name="fullName"
                    className="cs_form_field"
                    value={formData.fullName}
                    onChange={handleChange}
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <label className="cs_fs_21 cs_semibold cs_primary_color">Give valid email</label>
                  <input
                    placeholder="Type your email address"
                    type="email"
                    name="email"
                    className="cs_form_field"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <label className="cs_fs_21 cs_semibold cs_primary_color">Phone number</label>
                  <input
                    placeholder="Type your phone number"
                    type="text"
                    name="phone"
                    className="cs_form_field"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <label className="cs_fs_21 cs_semibold cs_primary_color">Project brief</label>
                  <textarea
                    name="message"
                    cols={30}
                    rows={5}
                    className="cs_form_field"
                    value={formData.message}
                    onChange={handleChange}
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <button onClick={handleMailtoClick} className="cs_btn cs_style_1">
                    Send Email{' '}
                    <span>
                      <i>
                        <Icon icon="fa6-solid:arrow-right" />
                      </i>
                      <i>
                        <Icon icon="fa6-solid:arrow-right" />
                      </i>
                    </span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="cs_height_0 cs_height_lg_80" />
      </section>
    </>
  );
}