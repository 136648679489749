import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import AboutStyle4 from '../About/AboutStyle4';
import FunFact from '../FunFact';
import AboutStyle5 from '../About/AboutStyle5';
import IconBoxStyle6 from '../IconBox/IconBoxStyle6';
import SectionHeadingStyle5 from '../SectionHeading/SectionHeadingStyle5';
import SectionHeading from '../SectionHeading';
import TeamSlider from '../Slider/TeamSlider';
import Marquee from '../Marquee';
import Brands from '../Brands';
import { pageTitle } from '../../helpers/PageTitle';

const funfactData = [
  { title: 'Happy Users', number: '900' },
  { title: 'Simulations Completed', number: '6,000' },
  { title: 'Industries Served', number: '8' },
  { title: 'Custom Scenarios ', number: '50+' },
];

const teamData = [
  {
    memberImg: '/images/studio-agency/team_1.jpeg',
    memberName: 'Dr. Joel D. Moore',
    memberDesignation: 'CEO & Founder',
    href: '/team/team-details',
  },
  {
    memberImg: '/images/studio-agency/team_2.jpeg',
    memberName: 'John Doe',
    memberDesignation: 'CTO',
    href: '/team/team-details',
  },
  {
    memberImg: '/images/studio-agency/team_3.jpeg',
    memberName: 'Alice Johnson',
    memberDesignation: 'Head of AI Development',
    href: '/team/team-details',
  },
  {
    memberImg: '/images/studio-agency/team_4.jpeg',
    memberName: 'Michael Brown',
    memberDesignation: 'Lead Educator',
    href: '/team/team-details',
  },
];

const brandList = [
  {
    logoSrc: '/images/marketing-agency/brand_1.svg',
    logoAlt: 'University A',
  },
  {
    logoSrc: '/images/marketing-agency/brand_2.svg',
    logoAlt: 'Company B',
  },
  {
    logoSrc: '/images/marketing-agency/brand_3.svg',
    logoAlt: 'Institution C',
  },
  {
    logoSrc: '/images/marketing-agency/brand_4.svg',
    logoAlt: 'Organization D',
  },
];

const brandListDark = [
  {
    logoSrc: '/images/marketing-agency/brand_1_dark.svg',
    logoAlt: 'University A',
  },
  {
    logoSrc: '/images/marketing-agency/brand_2_dark.svg',
    logoAlt: 'Company B',
  },
  {
    logoSrc: '/images/marketing-agency/brand_3_dark.svg',
    logoAlt: 'Institution C',
  },
  {
    logoSrc: '/images/marketing-agency/brand_4_dark.svg',
    logoAlt: 'Organization D',
  },
];

export default function AboutPage({ darkMode }) {
  pageTitle('About ATLAS');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="About ATLAS"
        subTitle="Bridging Theory and Practice"
        variant="text-center"
        shape="shape_1"
      />
      <Spacing lg="75" md="60" />
      <AboutStyle4
        thumbnailSrc="/images/digital-agency/about_1.jpeg"
        miniTitle="Our Mission"
        title="Enhancing Education Through AI-Powered Simulations"
        subTitle="At ATLAS, our mission is to enhance the efficacy of professional preparation programs by providing immersive, realistic scenarios that facilitate the application of theoretical knowledge in simulated practical situations. We aim to better equip future professionals for the complexities inherent in real-world environments across multiple fields."
        btnText="Learn More"
        btnUrl="/features"
      />
      <Spacing lg="125" md="70" />
      <div className="container">
        <FunFact data={funfactData} />
      </div>
      <Spacing lg="125" md="70" />
      <AboutStyle5
        variant="cs_type_1"
        thumbnailSrc="/images/digital-agency/about_2.jpeg"
        miniTitle="Our Approach"
        title="Combining AI Technology with Empirical Research"
        subTitle="ATLAS combines cutting-edge AI technology with empirical research and expert knowledge to create a controlled, interactive environment for experiential learning. Our platform is designed to work alongside traditional teaching methods, addressing limitations such as resource constraints and scheduling challenges in in-person simulations."
        progressBarList={[
          { title: 'AI-Powered Simulations', percentage: '90' },
          { title: 'Adaptive Learning', percentage: '85' },
          { title: 'Multi-Modal Interaction', percentage: '95' },
        ]}
        salesTitle="Innovation Level"
        groth="25"
      />
      <Spacing lg="150" md="80" />
      <section className="cs_primary_bg">
        <Spacing lg="140" md="70" />
        <div className="container">
          <SectionHeadingStyle5 title="Who We Serve" />
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/university.svg"
                  title="Educational Institutions"
                  subTitle="Universities and colleges offering professional degree programs."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/students.svg"
                  title="Students"
                  subTitle="Students in fields requiring strong interpersonal and communication skills."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/educators.svg"
                  title="Educators"
                  subTitle="Educators seeking innovative tools to enhance practical skills training."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_4.png"
                  iconSrc="/images/icons/professionals.svg"
                  title="Professionals"
                  subTitle="Professionals looking to refine their skills in a safe, adaptive environment."
                />
              </div>
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>
      <section className="cs_p76_full_width">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="Meet the Team Behind ATLAS"
            subTitle="Our Team"
          />
          <Spacing lg="85" md="45" />
        </div>
        <TeamSlider data={teamData} />
      </section>
      <Spacing lg="135" md="70" />
      <Marquee text="AI-Powered Simulations - Adaptive Learning - Multi-Modal Interaction - Professional Development" />
      <Spacing lg="84" md="50" />
      <div className="container">
        <Brands data={darkMode ? brandListDark : brandList} />
      </div>
      <Spacing lg="135" md="80" />
    </>
  );
}