import React from 'react';
import HeroStyle5 from '../Hero/HeroStyle5';
import Cta from '../Cta';
import Spacing from '../Spacing';
import SectionHeading from '../SectionHeading';
import PostCarousel from '../Slider/PostCarousel';
import PricingTableList from '../PricingTable/PricingTableList';
import TestimonialSliderStyle2 from '../Slider/TestimonialSliderStyle2';
import FunFact from '../FunFact';
import VideoModal from '../VideoModal';
import IconboxStyle4 from '../IconBox/IconboxStyle4';
import Card from '../Card';
import CardStyle2 from '../Card/CardStyle2';
import SectionHeadingStyle2 from '../SectionHeading/SectionHeadingStyle2';
import IconBoxStyle5 from '../IconBox/IconBoxStyle5';
import BrandCarousel from '../Slider/BrandCarousel';
import { pageTitle } from '../../helpers/PageTitle';

const brandData = [
  { logoSrc: '/images/tech-startup/brand_1.svg', alt: 'Brand' },
  { logoSrc: '/images/tech-startup/brand_2.svg', alt: 'Brand' },
  { logoSrc: '/images/tech-startup/brand_3.svg', alt: 'Brand' },
  { logoSrc: '/images/tech-startup/brand_4.svg', alt: 'Brand' },
  { logoSrc: '/images/tech-startup/brand_5.svg', alt: 'Brand' },
  { logoSrc: '/images/tech-startup/brand_6.svg', alt: 'Brand' },
];

const funfactData = [
  { title: 'Happy Users', number: '22k' },
  { title: 'Simulations Completed', number: '150k' },
  { title: 'Industries Served', number: '50+' },
  { title: 'Custom Scenarios', number: '500+' },
];

const testimonialData = [
  {
    text: 'ATLAS has transformed our training approach, making learning more interactive and practical.',
    avatarName: 'Dr. Emily Roberts',
    avatarDesignation: 'Professor at University XYZ',
  },
  {
    text: 'Our team\'s performance improved significantly after incorporating ATLAS into our training program.',
    avatarName: 'John Smith',
    avatarDesignation: 'Training Manager at ABC Corp.',
  },
  {
    text: 'I feel more confident entering the workforce thanks to the real-world practice ATLAS provides.',
    avatarName: 'Sarah Lee',
    avatarDesignation: 'Graduate Student',
  },
];

const postData = [
  {
    thumbnailSrc: '/images/creative-agency/post_1.jpeg',
    title: 'How ATLAS is Revolutionizing Corporate Training',
    date: '07 Mar 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_2.jpeg',
    title: 'Bridging the Gap Between Theory and Practice',
    date: '22 Apr 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_3.jpeg',
    title: 'The Future of AI in Education',
    date: '13 May 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_4.jpeg',
    title: 'Enhancing Learning with AI-Powered Simulations',
    date: '15 Mar 2023',
    url: '/blog/blog-details',
  },
];

export default function AtlasLandingPage() {
  pageTitle('ATLAS | AI-Powered Simulations for Education and Corporate Training');
  return (
    <>
      <HeroStyle5
        highlightTitle="Bridging Theory and Practice"
        title="ATLAS: Authentic Teaching and Learning Application Simulations"
        subTitle="Empower your students and professionals with AI-driven, personalized skills training."
        btnText="Request a Demo"
        btnUrl="/contact"
        videoSrc="https://www.youtube.com/embed/VcaAVWtP48A"
        videoBtnText="Watch Video Overview"
      />
      <div className="cs_primary_bg">
        <Spacing lg="95" md="60" />
        <div className="container">
          <BrandCarousel data={brandData} />
        </div>
        <Spacing lg="95" md="60" />
      </div>
      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="Revolutionize Learning with ATLAS"
            subTitle="Key Features"
            variant="cs_type_1"
          />
          <Spacing lg="85" md="45" />
          <div className="row g-3">
            <div className="col-lg-6">
              <IconboxStyle4
                iconSrc="/images/tech-startup/feature_icon_1.svg"
                title="AI-Powered Simulations"
                subTitle="Realistic scenarios that mimic professional interactions."
                btnText="Learn More"
                btnUrl="/features#ai-simulations"
              />
            </div>
            <div className="col-lg-6">
              <IconboxStyle4
                iconSrc="/images/tech-startup/feature_icon_2.svg"
                title="Real-Time Analysis and Feedback"
                subTitle="Immediate, personalized insights to enhance learning."
                btnText="Learn More"
                btnUrl="/features#real-time-feedback"
              />
            </div>
            <div className="col-lg-6">
              <IconboxStyle4
                iconSrc="/images/tech-startup/feature_icon_3.svg"
                title="Multi-Modal Interaction"
                subTitle="Engage through audio, visual, and text-based formats."
                btnText="Learn More"
                btnUrl="/features#multi-modal"
              />
            </div>
            <div className="col-lg-6">
              <IconboxStyle4
                iconSrc="/images/tech-startup/feature_icon_4.svg"
                title="Adaptive Learning Pathways"
                subTitle="Customized experiences based on user performance."
                btnText="Learn More"
                btnUrl="/features#adaptive-learning"
              />
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>
      <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeadingStyle2
            title="Who Can Benefit from ATLAS?"
            subTitle="Target Audience"
            btnText="Find Solutions for Your Industry"
            btnUrl="/solutions"
          />
          <Spacing lg="85" md="45" />
          <div className="row g-3">
            <div className="col-lg-6">
              <Card
                thumbnailSrc="/images/tech-startup/higher_education.jpeg"
                title="Higher Education Institutions"
                miniTitle="Education"
              />
            </div>
            <div className="col-lg-6">
              <Card
                thumbnailSrc="/images/tech-startup/corporate_training.jpeg"
                title="Corporations and Businesses"
                miniTitle="Corporate Training"
              />
            </div>
            <div className="col-lg-6">
              <Card
                thumbnailSrc="/images/tech-startup/students.jpeg"
                title="Students and Professionals"
                miniTitle="Learners"
              />
            </div>
            <div className="col-lg-6">
              <Card
                thumbnailSrc="/images/tech-startup/hr_professionals.jpeg"
                title="HR and L&D Professionals"
                miniTitle="Human Resources"
              />
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>
      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="Unlock the Full Potential of Learning"
            subTitle="Key Benefits"
            variant="cs_type_1"
          />
          <Spacing lg="85" md="45" />
          <div className="row g-3">
            <div className="col-lg-6 col-xl-4">
              <IconBoxStyle5
                iconSrc="/images/tech-startup/benefit_icon_1.svg"
                title="Enhanced Professional Preparation"
                subTitle="Ready individuals for real-world challenges."
                href="/benefits#professional-preparation"
              />
            </div>
            <div className="col-lg-6 col-xl-4">
              <IconBoxStyle5
                iconSrc="/images/tech-startup/benefit_icon_2.svg"
                title="Increased Confidence"
                subTitle="Build assurance in professional interactions."
                href="/benefits#increased-confidence"
              />
            </div>
            <div className="col-lg-6 col-xl-4">
              <IconBoxStyle5
                iconSrc="/images/tech-startup/benefit_icon_3.svg"
                title="Improved Communication Skills"
                subTitle="Develop essential interpersonal abilities."
                href="/benefits#communication-skills"
              />
            </div>
            <div className="col-lg-6 col-xl-4">
              <IconBoxStyle5
                iconSrc="/images/tech-startup/benefit_icon_4.svg"
                title="Better Knowledge Retention"
                subTitle="Reinforce learning through practical application."
                href="/benefits#knowledge-retention"
              />
            </div>
            <div className="col-lg-6 col-xl-4">
              <IconBoxStyle5
                iconSrc="/images/tech-startup/benefit_icon_5.svg"
                title="Cost-Effective Training"
                subTitle="Efficient alternative to traditional methods."
                href="/benefits#cost-effective"
              />
            </div>
            <div className="col-lg-6 col-xl-4">
              <IconBoxStyle5
                iconSrc="/images/tech-startup/benefit_icon_6.svg"
                title="Consistent Training Experiences"
                subTitle="Uniform learning across teams and locations."
                href="/benefits#consistent-training"
              />
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <Spacing lg="130" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/embed/VcaAVWtP48A"
          bgUrl="/images/tech-startup/video_bg.jpeg"
          title="Experience ATLAS in Action"
          titleVariant="cs_outline_text"
        />
      </div>
      <Spacing lg="125" md="70" />
      <div className="container">
        <FunFact data={funfactData} />
      </div>
      <Spacing lg="125" md="70" />
      <TestimonialSliderStyle2 data={testimonialData} />
      <section className="cs_shape_animation_2">
        <div className="cs_shape_1 position-absolute">
          {/* SVG or decorative shapes can be placed here */}
        </div>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="Flexible Pricing Solutions for Your Needs"
            subTitle="Our Pricing"
            variant="cs_type_1"
          />
          <Spacing lg="85" md="45" />
        </div>
        <div className="container">
          <PricingTableList />
        </div>
        <div className="cs_height_150 cs_height_lg_80" />
      </section>
      <section className="cs_p76_full_width cs_gray_bg_2">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="Stay Updated with Our Latest News"
            subTitle="Our Blog"
            variant="cs_type_1"
          />
          <Spacing lg="85" md="45" />
        </div>
        <PostCarousel data={postData} />
        <Spacing lg="150" md="80" />
      </section>
      <section>
        <Spacing lg="150" md="80" />
        <div className="container">
          <Cta
            title="Ready to Transform Learning?"
            btnText="Request a Demo"
            btnUrl="/contact"
            bgUrl="/images/tech-startup/cta_bg.jpeg"
            noShape
          />
        </div>
        <Spacing lg="150" md="80" />
      </section>
    </>
  );
}